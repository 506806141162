import axios from "axios";
import React, {useEffect, useState} from "react";
import {setCookie} from "../../util/util";
import Cookies from "js-cookie";
import {ToastProvider} from "react-toast-notifications";

import Webhook from "../../components/Webhooks/generic";
import {useDispatch} from "react-redux";
import {updateMany} from "../../features/webhookconfig/index";

// Assets

import {FaCcVisa} from "react-icons/fa";
import {IoMdExit} from "react-icons/io";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import Loader from "../../components/Loader";
import "./styles.scss";

// Field sets

import GeneralInfo from "../../components/GeneralInfo/new";
import {set } from "../../features/fields";
import {updateAll as discordUpdateAll} from "../../features/discordbotconfig";
import {updateAll as serverUpdateAll} from "../../features/serverconfig";

import DiscordBotInfo from "../../components/DiscordBotInfo/new";


const URL = "https://discord.com/api/oauth2/authorize?client_id=592457835397382156&redirect_uri=https%3A%2F%2Fwww.zephyrmonitors.com%2Fdashboard&response_type=token&scope=identify%20email";

const DashboardBetter = () => {
  const [user, setUser] = useState();
  const [group, setGroup] = useState();
  const [discordData, setDiscordData] = useState();
  const [page, setPage] = useState("Shopify Webhooks");
  const dispatch = useDispatch();


  // On mount make user login and get a token
  useEffect(() => {
    if (!user) {
      const getUserInfo = async (token) => {
        const userX = await axios
          .get("https://api.zephyrmonitors.com/api/zephyrAuth?token=" + token, {headers: headers})
          .then((res) => res.data)
          .catch((err) => console.log(err));

        if (!userX.status) {
          window.location = (URL);
         }
         if (userX) {

          setUser(userX);

          setGroup(userX.group);

          dispatch(updateMany(userX.group.webhooks));

          setDiscordData(userX.group.discordData);
    
     

          dispatch(discordUpdateAll(userX.group.discordbot));

          dispatch(serverUpdateAll(userX.group.general));
          
        } else {
          setUser(null);
        } 
      };

      const headers = {
        "x-api-key": "dsqHQBQXY1czAUDqKd0GabmcRWg6x9c2"
      };

      let token = "";

      if (window.location.hash.includes("token")) {
        token = window.location.hash.replace("#token_type=Bearer&", "").split("=")[1].split("&")[0];

        setCookie("discordToken", token);

        getUserInfo(token);
      } else if (!Cookies.get("discordToken")) {
window.location = (URL);
      } else {
        token = Cookies.get("discordToken");
        getUserInfo(token);
      }
    }
  }, [dispatch, user]);

  const logout = () => {
    Cookies.remove("discordToken");
    window.location.pathname = "";
  };

  console.log(group);
  return (
    <React.Fragment>
      {user && (
        <ToastProvider>
          <div className='dash-container'>
            <div className='nav-wrappers'>
              <div className='nav'>
                <Logo className='logo-img' />
                <div className='logout-container'>
                  <a className='logout' onClick={() => logout()}>
                    <IoMdExit />
                    Log Out
                  </a>
                </div>
              </div>

              <div className='header'>
                <div className='title-section'>
                  <p className='title'>Client Dashboard</p>
                  <span>Configure your server webhooks</span>
                </div>
                <div className='user-section'>
                  <div className='user-container'>
                    <img className='profile-img' src={discordData && discordData.avatar} alt='' />
                    <div className='user-info'>
                      <p className='username'>{discordData && discordData.formattedName}</p>
                      <p className='email'>{discordData && discordData.email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='dash-background'>
                <div className='dash-container'>
                  <div className='dash-header'>
                    <div className='dropdown-container'>
                      <select
                        className='dropdown'
                        value={page}
                        onChange={(e) => {
                          dispatch(set(e.target.value));
                          setPage(e.target.value);
                        }}>
                        <option className='selection' value='Shopify Webhooks' default>
                          Shopify Webhooks
                        </option>
                        <option className='selection' value='Nike Webhooks'>
                          Nike Webhooks
                        </option>
                        <option className='selection' value='Supreme Webhooks'>
                          Supreme Webhooks
                        </option>
                        <option className='selection' value='Adidas Webhooks'>
                          Adidas Webhooks
                        </option>
                        <option className='selection' value='AIO Webhooks'>
                          AIO Webhooks
                        </option>
                        <option className='selection' value='Social Media Webhooks'>
                          Social Media Webhooks
                        </option>
                        <option className='selection' value='Flips Webhooks'>
                          Flips Webhooks
                        </option>
                        <option className='selection' value='Scraper Webhooks'>
                          Scraper Webhooks
                        </option>
                        <option className='selection' value='Checkout Feeds/Other Webhooks'>
                          Checkout Feeds/Other Webhooks
                        </option>
                 
                      </select>
                    </div>
                    <div className='modal-button'>
                    <button className='open-modal' style={{"marginRight": "10px"}} onClick={() => setPage("Discord Bot Configuration")} >
                        <FaCcVisa className='robot-icon' size='1.2em' />
                        Bot Configuration
                      </button>
                      <button className='open-modal' style={{hidden: false}} onClick={() => (window.location = discordData.stripeDash)}>
                        <FaCcVisa className='robot-icon' size='1.2em' />
                        Stripe Dashboard
                      </button>
                    </div>
                    
                    <div className='server-c'>
                      <input type='button' onClick={() => setPage("Server Configuration")} value='Server Configuration' />
                    </div>
                    
                  </div>
                  {page === "Server Configuration" ? <GeneralInfo /> : ((page === "Discord Bot Configuration") ? <DiscordBotInfo /> : <Webhook page={page} />)}
                </div>
              </div>
              <div className='footer'>
                <div className='activated'>
                  <div className='ping'></div>
                  <p className='status active'>Active Subscription</p>
                </div>
                <p className='footer-text'>© Zephyr Monitors LLC</p>
                <div className='footer-links'>
                <a id='linked' className='footer-item' href='/terms'>
            Terms of Service
          </a>

        
                  <a href={"https://help.zephyrmonitors.com/monitorhelp/"}>Documentation</a>
                </div>
              </div>
            </div>
          </div>
        </ToastProvider>
      )}{" "}
      {!user && <Loader />}
    </React.Fragment>
  );
};

export default DashboardBetter;
