import React from "react";

import "./styles.scss";

const RadioButton = ({name, label, value, handleChange, currentValue}) => {
  return (
    <div className={"radio-container"}>
      <input type='radio' name={name} value={value} onChange={handleChange} checked={value === currentValue} />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default RadioButton;
