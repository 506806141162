import axios from "axios";



export const getDiscordAccessToken = async (token, uri) => {
  const data = {
    code: token,
    redirect: uri
  };
  const res = axios
    .post("https://application-api.zephyrmonitors.com/api/discord", data)
    .then((res) => {
      if (res.status === 200 && !res.data.error) return res.data;
      else return null;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });

  return res;
};
