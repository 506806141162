
import React from "react"
import NormalPages from ".."
export default class Terms extends NormalPages {
   content = () => {

    return (<div className='terms-wrapper'>
              <div className='terms-title'>
                <h2 className='main-title'>Terms of Conditions</h2>
              </div>
              <div className='terms-content'>
                <div className='sub-title'>Agreement between User and https://zephyrmonitors.com </div>
                <p>
                  Welcome to https://zephyrmonitors.com. The https://zephyrmonitors.com website (the "Site") is comprised of various web pages operated by Zephyr Monitors LLC ("Zephyr"). https://zephyrmonitors.com is offered to you
                  conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://zephyrmonitors.com constitutes your agreement to all such Terms. Please read
                  these terms carefully, and keep a copy of them for your reference. https://zephyrmonitors.com is an E-Commerce Site.
                </p>

                <div className='sub-title'>Purpose</div>
                <p>Zephyr monitors servers as both a marketing website as well as a client portal for new and existing customers to purchase digital services and manage their digital subscriptions.</p>

                <div className='sub-title'>Privacy</div>
                <p>Your use of https://zephyrmonitors.com is subject to Zephyr's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>

                <div className='sub-title'>Electronic Communications</div>
                <p>
                  Visiting https://zephyrmonitors.com or sending emails to Zephyr constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other
                  communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
                </p>

                <div className='sub-title'>Children Under Thirteen</div>
                <p>
                  Zephyr monitors ensures proper delivery of goods/services rendered. If delivery of the goods/services has been completed, Zephyr reserves the right to seek payment through a collection agency and/or civil litigation.
                  Clients will have 15 days’ notice of failed payment before alternative methods of payment collection will be pursued in accordance with US law.
                </p>

                <div className='sub-title'>Payment Collection</div>
                <p>
                  Zephyr monitors ensures proper delivery of goods/services rendered. If delivery of the goods/services has been completed, Zephyr reserves the right to seek payment through a collection agency and/or civil litigation.
                  Clients will have 15 days’ notice of failed payment before alternative methods of payment collection will be pursued in accordance with US law.
                </p>

                <div className='sub-title'>Cancellation/Refund Policy</div>
                <p>
                  Digital products or services CANCELLATION IS ALLOWED AT ANY TIME DURING YOUR SUBSCRIPTION PERIOD BY EMAILING OR OPENING A SUPPORT TICKET. WE ARE ABLE TO ASSIST SHOULD YOU RUN INTO ANY ISSUES THROUGH THE SAME CONTACT
                  POINTS. ACCEPTANCE OF SERVICES AND/OR GOODS IS VALID UPON RECEIPT OF DELIVERY BY ZEPHYR MONITORS. BY PURCHASING THESE PRODUCTS AND/OR USING THESE PRODUCTS YOU TAKE ALL RESPONSIBILITY, ACCOUNTABILITY, AND LIABILITY. ZEPHYR
                  MONITORS AS THE SELLER/PRODUCER/RETAILER ARE NOT RESPONSIBLE FOR ANY OUTCOME OF PRODUCT USAGE. THIS SERVICE DOES NOT GUARANTEE SUCCESS IN ANY TERMS. NO REFUNDS, REPLACEMENTS, RETURNS WILL BE GIVEN OUT UNDER ANY
                  CIRCUMSTANCES. UNLESS OTHERWISE SPECIFIED, ALL PURCHASES ON THIS WEBSITE ARE DIGITAL SERVICES CONSISTING OF WEBSITE MONITORS, LINK COLLECTION, AND DIGITAL SOFTWARE. DUE TO THE DIGITAL NATURE OF THESE GOODS, REFUNDS AND/OR
                  RETURNS ARE NOT POSSIBLE AND THUS ALL PURCHASES ARE CONSIDERED FINAL SALE. ANY ATTEMPT TO USE A BANK OR THIRD PARTY TO REVERSE A PAYMENT OR “CHARGEBACK” ON ZEPHYR MONITORS WILL BE CONSIDERED FRAUDULENT AND PUNISHABLE UNDER
                  THE LAWS OF CALIFORNIA AND THE UNITED STATES UNDER 18 U.S. CODE § 1341. ZEPHYR MONITORS RESERVES THE RIGHT TO REPORT AND PURSUE DAMAGES SHOULD PAYMENT FOR DELIVERED GOODS BE REVERSED.
                </p>

                <div className='sub-title'>No Unlawful or Prohibited Use/Intellectual Property</div>
                <p>
                  You are granted a non-exclusive, non-transferable, revocable license to access and use https://zephyrmonitors.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to
                  Zephyr that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any
                  other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site. All content included as
                  part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Zephyr or its suppliers and protected by copyright and other laws that
                  protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes
                  thereto. You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Zephyr
                  content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in
                  any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Zephyr and the copyright owner. You agree that you do not
                  acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Zephyr or our licensors except as expressly authorized by these Terms.
                </p>

                <div className='sub-title'>Third Party Accounts</div>
                <p>
                  https://zephyrmonitors.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Zephyr and Zephyr is not responsible for the contents of any Linked Site, including without
                  limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Zephyr is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Zephyr
                  of the site or any association with its operators. Certain services made available via https://zephyrmonitors.com are delivered by third party sites and organizations. By using any product, service or functionality
                  originating from the https://zephyrmonitors.com domain, you hereby acknowledge and consent that Zephyr may share such information and data with any third party with whom Zephyr has a contractual relationship to provide the
                  requested product, service or functionality on behalf of https://zephyrmonitors.com users and customers.
                </p>

                <div className='sub-title'>Links to Third Party Sites/Third Party Services</div>
                <p>
                  You will be able to connect your Zephyr account to third party accounts. By connecting your Zephyr account to your third-party account, you acknowledge and agree that you are consenting to the continuous release of
                  information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.
                </p>

                <div className='sub-title'>International Users</div>
                <p>
                  The Service is controlled, operated and administered by Zephyr from our offices within the USA and in compliance with United States law. If you access the Service from a location outside the USA, you are responsible for
                  compliance with all local laws. You agree that you will not use the Zephyr Content accessed through https://zephyrmonitors.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
                </p>

                <div className='sub-title'>Indemnification</div>
                <p>
                  You agree to indemnify, defend and hold harmless Zephyr, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or
                  arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any
                  applicable laws, rules or regulations. Zephyr reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully
                  cooperate with Zephyr in asserting any available defenses.
                </p>

                <div className='sub-title'>Arbitration</div>
                <p>
                  In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for
                  damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American
                  Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court
                  having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and
                  reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly,
                  including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the
                  scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.
                </p>

                <div className='sub-title'>Class Action Waiver</div>
                <p>
                  Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS
                  AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
                  AGAINST THE OTHER. Further, unless both you and Zephyr agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
                </p>

                <div className='sub-title'>Liability Disclaimer</div>
                <p>
                  THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. ZEPHYR MONITORS LLC
                  AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. ZEPHYR MONITORS LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
                  OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                  GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. ZEPHYR MONITORS LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES
                  AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
                  ZEPHYR MONITORS LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
                  PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                  INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
                  ZEPHYR MONITORS LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                  THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
                </p>

                <div className='sub-title'>Termination/Access Restriction</div>
                <p>
                  Zephyr reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is
                  governed by the laws of the State of California and you hereby consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to the use of the Site. Use of the Site is
                  unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section. If a user has been removed from the service or notified of their termination of
                  access, Zephyr Monitors holds no responsibility should the user attempt to access the website using alternative means. Additionally, once usage has been terminated, the user has waived all rights under the law and shall be
                  considered an unauthorized user. You agree that no joint venture, partnership, employment, or agency relationship exists between you and Zephyr as a result of this agreement or use of the Site. Zephyr's performance of this
                  agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Zephyr's right to comply with governmental, court and law enforcement requests or requirements relating
                  to your use of the Site or information provided to or gathered by Zephyr with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not
                  limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent
                  of the original provision and the remainder of the agreement shall continue in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Zephyr with respect to the Site
                  and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Zephyr with respect to the Site. A printed version of this agreement and of any notice
                  given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records
                  originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
                </p>

                <div className='sub-title'>Client Usage Terms</div>
                <p>
                  1. Zephyr Monitors must not be accessible to the public. <br />
                  This means you cannot have open invitations to your group that grant access to the monitors or other digital services. Should you provide these products open source, you will be liable for any damages suffered by Zephyr
                  and its affiliates.
                  <br />
                  <br />
                  2. Zephyr Monitors and all other products on this site may not be resold or redistributed as derivative works.
                  <br />
                  <br />
                  3. We reserve the right to remove Zephyr Monitors advertising from your group's social media or website. Marketing using the Zephyr brand is prohibited unless permission is requested and granted by Zephyr and/or its
                  affiliates.
                  <br />
                  <br />
                  4. Viewing and usage of our products requires at minimum a $10 a month subscription, unless otherwise authorized. Those using these services at below this minimum rate risk cancellation/termination of their use unless they
                  contact us.
                  <br />
                  <br />
                  5. Zephyr Monitors reserves the right to request usage documentation and view this usage in the group or server where it is provided. Failure to grant this viewership or usage documentation may result in termination of
                  your subscription.
                </p>

                <div className='sub-title'>Changes to Terms</div>
                <p>
                  Zephyr reserves the right, in its sole discretion, to change the Terms under which https://zephyrmonitors.com is offered. The most current version of the Terms will supersede all previous versions. Zephyr encourages you to
                  periodically review the Terms to stay informed of our updates.
                </p>

                <div className='sub-title'>Oral Modifications</div>
                <p>No amendments or modifications to this agreement shall be binding unless put forth in writing.</p>

                <div className='sub-title'>Entire Agreement</div>
                <p>This term of use document constitutes all existing policy under the Zephyr Monitors brand for the usage of the website.</p>

                <div className='sub-title'>Changes to Terms</div>
                <p>
                  Zephyr welcomes your questions or comments regarding the Terms:
                  <br />
                  Zephyr Monitors LLC 43547 Southerland Way Fremont, California 94539
                  <br />
                  <br />
                  Email Address:
                  <br />
                  monitors@zephyrmonitors.com
                </p>
              </div>
            </div>)
   }
}
