import React from "react";
import RadioButton from "../../General/Radio";

const Card = ({name, radioFields, label, subText, placeholder, imgUrl, inputType, inputValue, handleChange, state, service}) => {
  const generateTextInput = () => {
    return <input type={inputType} placeholder={placeholder} value={inputValue} onChange={(e) => handleChange("HANDLE_INPUT_TEXT", e)} name={name} maxLength={1024} required={true} />;
  };

  const radioInputChangeWrapper = (e) => {
    if (name === "contactMethod") {
      handleChange("HANDLE_INPUT_RADIO", e);
      handleChange("HANDLE_CLEAR_OPTIONAL_FIELD", e);
    } else handleChange("HANDLE_INPUT_RADIO", e);
  };

  const generateTerms = () => {
    if (name === "consent") {
      return (
        <div className='terms'>
          {service === "monitors" && <p>Upon submission, you will be added to our Discord server. The price is $500/m for discord groups for the full package. Smaller packages are dealt on a case by case basis. We also offer integrations for bots.</p>}

          {service === "links" && <p>Zephyr Links is $150 to $400 monthly, non-negotiable, and non-modular.</p>}
          <p>
            By submitting this application, you agree to Zephyr Monitor's <a href='https://www.zephyrmonitors.com/terms'>Terms of Service</a> and <a href='https://www.zephyrmonitors.com/policy'>Privacy Policy</a>.
          </p>
        </div>
      );
    }
  };
  const generateRadioInput = (value) => {
    return <RadioButton handleChange={(e) => radioInputChangeWrapper(e)} name={name} label={value} value={value} required={true} currentValue={state[name]} />;
  };

  const generateInput = () => {
    const handleExtraInfo = () => {
      if (state[name] === "Other") {
        return <input type={"text"} placeholder={"Enter contact method"} value={state["otherInfo"]} onChange={(e) => handleChange("HANDLE_INPUT_TEXT", e)} name={"otherInfo"} required={true} maxLength={1024} />;
      }
    };
    const generateRadioOptions = () => {
      return radioFields.map((option) => {
        return (
          <React.Fragment key={option}>
            {generateRadioInput(option)}
            {option === "Other" && handleExtraInfo()}
          </React.Fragment>
        );
      });
    };
    switch (inputType) {
      case "radio":
        return generateRadioOptions();
      default:
        return generateTextInput();
    }
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <img src={imgUrl} alt='' />
        <p className='card-title'>{label}</p>
      </div>
      <div className='card-description'>
        {name === "consent" && generateTerms()}
        {name !== "consent" && subText}
      </div>
      {generateInput()}
    </div>
  );
};

export default Card;
