

const aioV2Fields = [
    {label: "Taf", dataField: "tafmx"},
    {label: "Galerie La Fayette", dataField: "galerielafayette"},
    {label: "Stax Shop", dataField: "staxshop"},
    {label: "Foot Asylum", dataField: "footasylum"},
    {label: "Triads", dataField: "triads"},
    {label: "Nojima", dataField: "nojima"},
    {label: "Nigra Mercato", dataField: "nigramercato"},
    {label: "Wood Wood", dataField: "woodwood"},
    {label: "Brand Shop", dataField: "brandshop"},
    {label: "Holy Pop Store", dataField: "holypopstore"},
    {label: "Soto Store", dataField: "sotostore"},
    {label: "Miniramp", dataField: "miniramp"},
    {label: "Foot Shop", dataField: "footshop"},
    {label: "Shelta", dataField: "sheltaeu"},
    {label: "Tike", dataField: "tikesi"},
    {label: "BAIT", dataField: "baitme"},
    {label: "Reynolds and Sons", dataField: "reynoldssons"},
    {label: "Shiekh", dataField: "shiekh"},
    {label: "Dicks Sporting Goods", dataField: "dsg"},
    {label: "Sneakersnstuff", dataField: "sns"},
    {label: "asos", dataField: "asos"},
    {label: "New Balance", dataField: "newbalance"},
    {label: "My Navy Exchange", dataField: "mynavyexchange"},
    {label: "Shop My Exchange", dataField: "shopmyexchange"},
    {label: "YME Universe", dataField: "ymeuniverse"},
    {label: "Selfridges", dataField: "selfridges"},
    {label: "Golf Galaxy", dataField: "golfgalaxy"},
    {label: "Courir", dataField: "courir"},
    {label: "NBA Store EU", dataField: "nbastoreeu"},
    {label: "Consortium UK", dataField: "consortium"},
    {label: "Pacsun", dataField: "pacsun"},
    {label: "Off White", dataField: "offwhite"},
    {label: "Converse", dataField: "converse"},
    {label: "arktz", dataField: "arktzshopjp"},
    {label: "Kickz", dataField: "kickz"},
    {label: "MVP Basketball Store", dataField: "mvpbasketballstore"}
];

export default aioV2Fields;
