import React from "react";
import ReactDOM from "react-dom";

//import containers
import Routes from "./routes";
import store from "./app/store";
import {Provider} from "react-redux";
/**import { datadogRum } from '@datadog/browser-rum';



datadogRum.init({
  applicationId: '358624ac-63c9-4064-bdae-a3df6ec5cf5e',
  clientToken: 'puba2118afd770644805387ac7e70be838a',
  site: 'datadoghq.com',
  service:'zephyrmonitors-client',
  env: 'production',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sampleRate: 50,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: false,
  defaultPrivacyLevel:'allow'
});
  
datadogRum.startSessionReplayRecording();

**/
try {
  ReactDOM.render(
    <Provider store={store}>
      <Routes />
    </Provider>,
    document.querySelector("#root")
  );
} catch (err) {
  // do nothing
}
