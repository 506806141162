import {configureStore} from "@reduxjs/toolkit";
import {webhookSlice} from "../features/webhookconfig";

import {discordConfigSlice} from "../features/discordbotconfig";
import {serverConfigSlice} from "../features/serverconfig";

const store = configureStore({
  reducer: {
    webhooks: webhookSlice.reducer,

    discordbot: discordConfigSlice.reducer,
    general: serverConfigSlice.reducer
  }
});

export default store;
