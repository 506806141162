import React, {useEffect, useState, useCallback} from "react";
import submit from "../../actions/submit"
import {useSelector, useDispatch} from "react-redux";
import {updateMany} from "../../features/webhookconfig";
import "./styles.scss";



const WebhookForm = ({customFields}) => {
  const webhooksRedux = useSelector((state) => state.webhooks);

  const [formState, setFormState] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const filtered = Object.entries(webhooksRedux).filter(([key, value]) => customFields.find((obj) => obj.dataField === key));

    const ret = {};

    filtered.map((obj) => (ret[obj[0]] = obj[1]));

    setFormState(ret);
  }, [webhooksRedux]);

  const onChangeHandler = useCallback(({target: {name, value}}) => setFormState((state) => ({...state, [name]: value}), []), []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newData = Object.assign({}, formState);
    newData["type"] = "webhooks";

    await submit((newData))
      .then((res) => {
        const response = res.data;

        if (response.status) {
          setIsLoading(false);
          dispatch(updateMany(formState));
        } else {
          setIsLoading(false);
        }
      })
  };

  return (
    <form>
      {formState &&
        customFields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <label htmlFor={field.dataField}>{field.label}</label>
              {field.description && <p className='input-desc'>{field.description}</p>}
              <input type={"text"} name={field.dataField} placeholder={`Enter webhook`} value={formState[field.dataField] ? formState[field.dataField] : ""} onInput={onChangeHandler} />
            </React.Fragment>
          );
        })}
      <div className='save-btn-container'>
        {!isLoading ? (
          <button className='btn save break' id='saveInfo' onClick={(e) => onSubmit(e)}>
            Save Changes
          </button>
        ) : (
          <button className='btn save break' id='saveInfo'>
            Loading
          </button>
        )}
      </div>
    </form>
  );
};

export default WebhookForm;
