
import {ReactComponent as Logo} from "../../../assets/images/logo.svg";
import {FaTwitterSquare} from "react-icons/fa";


import React from 'react';
const genFooter = () => {

    return (

<div className='footer-container'>
<div className='container'>
  <div className='footer-inner-top'>
    <div className='menu-items'>
      <div className='menu row'>
        <p className='footer-title'>Home</p>
        <hr />

          <a id='linked' className='footer-item' href='/'>
            Home
          </a>


          <a id='linked' className='footer-item' href='/dashboard'>
            Dashboard
          </a>


          <a id='linked' className='footer-item' href='/apply/monitors'>
            Apply
          </a>

      </div>

      <div className='menu row'>
        <p className='footer-title'>Websites</p>
        <hr />
        <a className='footer-item' href={"https://zephyraio.com"}>
          Zephyr AIO
        </a>
        <a className='footer-item'href={"https://links.zephyrmonitors.com"}>
          Zephyr Links
        </a>
        <a className='footer-item'href={"https://zephyrmonitorsllc.com"}>
          Our Company
        </a>
      </div>

      <div className='menu row'>
        <p className='footer-title'>Legal</p>
        <hr />

          <a id='linked' className='footer-item' href='/terms'>
            Terms of Service
          </a>

          <a id='linked' className='footer-item'href='/policy'>
            Privacy Policy
          </a>

      </div>

      <div className='menu row'>
        <p className='footer-title'>Support</p>
        <hr />
        <a className='footer-item' href='mailto:monitors@zephyrmonitors.com'>
          Email Us
        </a>
      </div>
    </div>

    <div className='menu row'>
      <p className='footer-title'>Follow Us</p>
      <hr />
      <div className='column'>
        <a className='social-icon' href='https://twitter.com/ZephyrMonitors'>
          <FaTwitterSquare size={25} />
        </a>
      </div>
    </div>
  </div>
  <div className='footer-inner'>
    <div className='logo-nav'>
      <Logo className='logo-img' />
    </div>
    <div className='copyright'>© Zephyr Monitors LLC</div>
  </div>
</div>
</div>)}


export default genFooter;