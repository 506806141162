// import dependencies
import React from "react";

// import assets
import {ReactComponent as Logo} from "./../../assets/images/logo.svg";
import "./styles.scss";
import {Spiral as Hamburger} from "hamburger-react";
import {Link} from "react-router-dom";
import genFooter from "./../General/Footer"
export default class NormalPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      cookieOpen: true,
      isDesktop: true
    };
    this.isOpenOnToggle = this.isOpenOnToggle.bind(this);
    this.content = this.isOpenOnToggle.bind(props.content);

  }

  isOpenOnToggle() {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {

    let menuMarkdown = null;
    let desktopMarkdown = null;
    if (this.state.isOpen) {
      menuMarkdown = (
        <React.Fragment>
          <div className='nav-modal'>
            <div className='dropdown-nav'>
              <Logo id='nav-logo' className='logo-nav' />
              <div className='nav--items'>
                <div className='nav-item'>
                  <Link to='/'>
                 Home
                  </Link>
                </div>
                <div className='nav-login'>
                  <Link to='/dashboard'>
                  Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (this.state.isDesktop) {
      desktopMarkdown = (
        <React.Fragment>
          <div className='nav--items-desktop visible'>
            <div className='nav-item'>
              <Link to='/'>
              Home
              </Link>
            </div>
            <div className='nav-item'>
              <Link to='/#features'>
             Features
              </Link>
            </div>
            <div className='nav-login'>
              <Link to='/dashboard'>
             Dashboard
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className='main-container'>
          <div className='nav-container'>
            <nav>
              <div className='nav-wrapper'>
                <div className='nav-inner'>
                  <div className='logo-nav'>
                    <Logo className='logo-img' />
                  </div>
                  {desktopMarkdown}
                  <button className='mobile-dropdown-toggle' onClick={this.isOpenOnToggle} aria-hidden='true'>
                    <Hamburger size={25} direction='right' />
                  </button>

                  {menuMarkdown}
                </div>
              </div>
            </nav>
          </div>

          <div className='container'>
          {this.content()}
          </div>

          {genFooter()}
        </div>
      </React.Fragment>
    );
  }
}
