
import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

//import containers

import DashboardBetter from "./containers/Dashboard/new";
import Home from "./containers/Home";

//import components


import ScrollToTop from "./components/ScrollToTop";
import WaitingRoom from "./components/WaitingRoom";
import MonitorsFormPage from "./components/WaitingRoom/monitorsformpage";
import ThankYou from "./components/WaitingRoom/thankyou";
import LinksFormPage from "./components/WaitingRoom/linksformpage";

import Terms from "./components/NormalPages/helpers/terms"
import Privacy from "./components/NormalPages/helpers/privacy";


export default () => {
  return (
    <Router>
      <React.Fragment>
        <ScrollToTop />
        <Route exact path='/' component={Home} />
        <Route exact path='/dashboard' component={DashboardBetter} />
        <Route exact path='/terms'   component={Terms}  />
        <Route exact path='/policy' component={Privacy} />
        {/** Service Application Routes */}
        <Route
          exact
          path='/apply/:service'
          component={(props) => {
            const service = props.match.params.service.toLowerCase();

            if (service === "monitors" || service === "links") return <WaitingRoom />;
            else return <Home />;
          }}
        />
        <Route
          exact
          path='/apply/:service/form'
          component={(props) => {
            const service = props.match.params.service.toLowerCase();

            if (service === "monitors") return <MonitorsFormPage />;
            else if (service === "links") return <LinksFormPage />;
            else return <Home />;
          }}
        />
        <Route exact path='/success' component={ThankYou} />
      </React.Fragment>
    </Router>
  );
};
