import React, {useState} from "react";
import NavModal from "./navmodal";

import {ReactComponent as Logo} from "../../../assets/images/logo.svg";
import Hamburger from "hamburger-react";
import {Link} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";

/** Props
 * showFeatures: Boolean
 *
 */
const Nav = ({showFeatures}) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Generates the desktop menu
   */
  const generateDesktopMenu = () => {
    return (
      <div className='nav--items-desktop visible'>
        <div className='nav-item'>
          <Link to='/'>Home</Link>
        </div>
        {!showFeatures && (
          <div className='nav-item'>
            <ScrollLink to='features' smooth={true} duration={1000}>
              Features
            </ScrollLink>
          </div>
        )}
        <div className='nav-login'>
          <Link to='/dashboard'>Dashboard</Link>
        </div>
      </div>
    );
  };

  return (
    <div className='nav-container'>
      <nav>
        <div className='nav-inner'>
          <div className='logo-nav'>
            <Logo className='logo-img' />
          </div>
          {generateDesktopMenu()}
          <button className='mobile-dropdown-toggle' onClick={() => setIsOpen(!isOpen)} aria-hidden='true'>
            <Hamburger size={25} direction='right' />
          </button>
          {/** Handles Modal pop up */}
          {isOpen && <NavModal />}
        </div>
      </nav>
    </div>
  );
};

export default Nav;
