import {createSlice} from "@reduxjs/toolkit";

export const webhookSlice = createSlice({
  name: "webhooks",
  initialState: {},
  reducers: {
    update: (state, action) => {

      const updated = state;

      const target = updated.findIndex((obj) => obj.dataField === action.payload.dataField);

      updated[target].value = action.payload.value;

      state = {...updated};
    },
    updateMany: (state, action) => {

      const updated = Object.assign({}, state);

      Object.entries(action.payload).forEach(([key, value]) => (updated[key] = value));

      return updated;
    }
  }
});

export const {update, updateMany} = webhookSlice.actions;

export const selectState = (state) => state;

export default webhookSlice.reducer;