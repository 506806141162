import Cookies from 'js-cookie'
import axios from "axios";

const submit = (enteredData) =>{
   return axios.post(`https://api.zephyrmonitors.com/api/zephyrNewSave?token=${Cookies.get("discordToken")}`,
    JSON.stringify(enteredData), {
        headers: {
          "x-api-key": "dsqHQBQXY1czAUDqKd0GabmcRWg6x9c2",
          "content-type":"application/json",
          "accept": "application/json"
    //      "x-generation-date": process.env.NODE_ENV,
      //    "x-zephyr-version": process.env.DATE
        },
      })
}

export default submit;