// import dependencies
import React from "react";
import Cookies from "js-cookie";

// import assets

import "./styles.scss";
import Feed from "../../assets/images/feed.gif";
import SVG from "react-inlinesvg";
import Globe from "../../assets/images/z-globe.png";
import {BsArrowRightShort} from "react-icons/bs";
import Client from "../../assets/images/client.png";
import Custom from "../../assets/images/custom.svg";
import Alert from "../../assets/images/alert.svg";
import Release from "../../assets/images/release.svg";
import Tool from "../../assets/images/tool.svg";
import Checker from "../../assets/images/checker.svg";
import Twitter from "../../assets/images/twitter.svg";
import LX from "../../assets/images/lx.png";
import AIO from "../../assets/images/aio.png";
import Links from "../../assets/images/links-dash.png";
import ScrollAnimation from "react-animate-on-scroll";

import {Link as Link1} from "react-router-dom";
import genFooter from "../../components/General/Footer";
//import site-images
import Shopify from "../../assets/images/shopify.svg";
import Supreme from "../../assets/images/supreme.svg";
import Footsites from "../../assets/images/footsites.svg";
import Nike from "../../assets/images/nike.svg";
import Adidas from "../../assets/images/adidas.svg";
import Mesh from "../../assets/images/mesh.svg";
import Aio from "../../assets/images/aio.svg";
import Antibot from "../../assets/images/antibot.svg";
import Nav from "../../components/General/Nav/nav";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isDesktop: true,
      clicked: false
    };
    this.isOpenOnToggle = this.isOpenOnToggle.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.setCookie = this.setCookie.bind(this);
  }

  onAccept() {
    this.setState({clicked: true});
    this.setCookie("terms", "1");
  }
  componentDidMount() {
    if (Cookies.get("terms") === "1") {
      this.setState({clicked: true});
    }
  }
  isOpenOnToggle() {
    this.setState({isOpen: !this.state.isOpen});
  }
  setCookie(name, value) {
    var expiry = new Date(new Date().getTime() + 30 * 24 * 3600 * 100000);
    document.cookie = name + "=" + escape(value) + "; domain=zephyrmonitors.com; path=/; expires=" + expiry.toGMTString();
  }
  render() {
    return (
      <React.Fragment>
        <div className='main-container'>
          {!this.state.clicked ? (
            <div className='row-policy fixed-b'>
              <div className='cookie-wrapper'>
                <div className='cookie-inner'>
                  <div className='policy-title'>This website uses cookies</div>
                  <div className='policy-description'>We use cookies to improve your experience on our site. By continuing to browse this website, you accept the use of cookies in your session.</div>
                  <div className='cookie-buttons'>
                    <button className='cookie-btn accept' onClick={this.onAccept}>
                      Accept
                    </button>
                    <a href='/terms' className='cookie-btn learn-more'>
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Nav />
          <div className='hero-container'>
            <div className='hero-background'></div>
            <div className='container'>
              <div className='hero-wrapper'>
                <div className='row spacing split'>
                  <div className='hero-text inner-container'>
                    <h1 className='hero-title animate__animated animate__fadeIn'>
                      The <span className='blue--accent'>new</span> standard in monitors
                    </h1>
                  </div>
                  <div className='hero-caption inner-container animate__animated animate__fadeIn'>
                    <p>Providing clients one of the fastest and reliable monitors in the market.</p>
                  </div>
                  <div className='btn-dual-container inner-container animate__animated animate__fadeIn'>
                    <Link1 to='/apply/monitors'>
                      <a className='apply'>Apply Now</a>
                    </Link1>
                    <Link1 to='dashboard'>
                      <a className='learn'>Dashboard</a>
                    </Link1>
                  </div>
                </div>
                <div className='hero-image animate__animated animate__fadeIn'>
                  <img src={Feed} alt='Feed' />
                </div>
              </div>
            </div>
          </div>

          <ScrollAnimation animateIn='fadeIn'>
            <div className='container pad-top-m'>
              <div className='row-site center'>
                <div className='section-header'>
                  <div className='blue-title'>Featured Sitelist</div>
                  <h2 className='main-title'>Over 9+ Platforms Monitored</h2>
                </div>
                <div className='site-list'>
                  <SVG src={Shopify} alt='Shopify' />
                  <SVG src={Supreme} alt='Supreme' />
                  <SVG src={Nike} alt='Nike' />
                  <SVG src={Adidas} alt='Adidas' />
                  <SVG src={Footsites} alt='Footsites' />
                  <SVG src={Mesh} alt='Mesh' />
                  <SVG src={Aio} alt='AIO' />
                  <SVG src={Antibot} alt='Antibot' />
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn='fadeIn' duration={1.5}>
            <div className='stats-container pad-top'>
              <div className='grid-background'></div>
              <div className='globe-container'>
                <img className='globe-img' src={Globe} alt='' />
              </div>
              <div className='stats-wrapper'>
                <div className='row-stats'>
                  <div className='stat-item'>
                    <div className='stat-header'>320+</div>
                    <div className='stat-caption'>Sites Monitored</div>
                  </div>
                  <div className='stat-item'>
                    <div className='stat-header'>1000+</div>
                    <div className='stat-caption'>Items Monitored Per Week</div>
                  </div>
                  <div className='stat-item'>
                    <div className='stat-header'>99.5%</div>
                    <div className='stat-caption'>Guaranteed Uptime</div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn='fadeIn' duration={1.5}>
            <div className='container pad-top' id='feature-section'>
              <div className='section-header'>
                <div className='blue-title'>Client Dashboard</div>
                <h2 className='main-title'>Meet your Personalized Dashboard</h2>
              </div>

              <div className='client-box'>
                <div className='circle-background'></div>
                <div className='box-header'>
                  <div className='box-title'>Configure your server in one place</div>
                  <div className='start-now'>
                    <a href='#' className='box-link'>
                      Start Now <BsArrowRightShort size='1.6em' />
                    </a>
                  </div>
                </div>
                <section id='features'>
                  <div className='box-img'>
                    <img src={Client} alt='client-dash' className='client-img' />
                  </div>
                </section>
              </div>
              <div className='feature--items'>
                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Custom} alt='keyword pinger' />
                  </div>
                  <div className='feature-title'>Custom Keyword Pinger</div>
                  <div className='feature-caption'>A robust notification system customized to each and every user</div>
                </div>

                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Alert} alt='restock alerts' />
                  </div>
                  <div className='feature-title'>Restock Alerts</div>
                  <div className='feature-caption'>Easily alert your server when the most profitable products restock</div>
                </div>

                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Release} alt='release channels' />
                  </div>
                  <div className='feature-title'>Custom Release Channels</div>
                  <div className='feature-caption'>Tailor your monitor's keywords and websites to your server's needs</div>
                </div>

                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Tool} alt='shopify tools' />
                  </div>
                  <div className='feature-title'>Shopify Tools</div>
                  <div className='feature-caption'>Easily retrieve shipping rates and variants with advanced Shopify tools </div>
                </div>

                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Checker} alt='stock checker' />
                  </div>
                  <div className='feature-title'>Nike Stock Checker</div>
                  <div className='feature-caption'>Track Nike stock across several regions at any time on any product</div>
                </div>

                <div className='feature-item'>
                  <div className='feature-icon'>
                    <SVG src={Twitter} alt='twitter' />
                  </div>
                  <div className='feature-title'>Twitter Success Poster</div>
                  <div className='feature-caption'>Celebrate your success with your Twitter audience instantaneously</div>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <div className='container pad-top'>
            <ScrollAnimation animateIn='pulse' duration={1.5}>
              <div className='section-header'>
                <div className='blue-title'>Partners</div>
                <h2 className='main-title'>Shop Zephyr Products</h2>
              </div>

              <div className='partner-container'>
                <div className='partner-box aio'>
                  <div className='partner-title'>ZephyrAIO</div>
                  <div className='partner-heading'>Botting, Revolutionized</div>
                  <a href='https://aio.zephyrmonitors.com/' className='box-link'>
                    Learn more <BsArrowRightShort size='1.6em' />
                  </a>
                  <div className='partner-img'>
                    <img className='aio-img' src={AIO} alt='' />
                  </div>
                </div>
                <div className='partner-box z-links'>
                  <div className='partner-title'>Zephyr Links</div>
                  <div className='partner-heading'>Providing quality to early links</div>
                  <a href='https://links.zephyrmonitors.com/' className='box-link'>
                    Learn more <BsArrowRightShort size='1.6em' />
                  </a>
                  <div className='partner-img'>
                    <img className='links-img' src={Links} alt='' />
                  </div>
                </div>
                <div className='partner-box launchedx'>
                  <div className='partner-title'>LaunchedX</div>
                  <div className='partner-heading'>Never miss a restock again</div>
                  <a href='https://launchedx.io/' className='box-link'>
                    Learn more <BsArrowRightShort size='1.6em' />
                  </a>
                  <div className='partner-img'>
                    <img className='links-img' src={LX} alt='' />
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className='join-container'>
            <div className='container'>
              <div className='join-wrapper'>
                <div className='section-header'>
                  <div className='blue-title'>Join Now</div>
                  <h2 className='main-title'>Get started with Zephyr today</h2>
                  <Link1 to='/apply/monitors'>
                    <a className='trial-link'>
                      Apply Now <BsArrowRightShort size='1.6em' />
                    </a>
                  </Link1>
                </div>
              </div>
            </div>
          </div>
 {genFooter()}
        </div>
      </React.Fragment>
    );
  }
}
