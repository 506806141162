
import React from "react"
import NormalPages from ".."
export default class Privacy extends NormalPages {
   content = () => {
    return (<div className='terms-wrapper'>
              <div className='terms-title'>
                <h2 className='main-title'>Privacy Policy</h2>
              </div>
              <div className='terms-content'>
                <div className='sub-title'>Applications</div>
                <p>
                  Privacy is a priority to our company and all our subsidiaries. This Statement of Privacy applies to https://zephyrmonitors.com/, https://aio.zephyrmonitors.com/, and https://links.zephyrmonitors.com/ and governs data
                  collection and usage. This agreement was written in accordance to all state and federal guidelines at the time of publishing. For the purposes of this Privacy Policy, unless otherwise noted, all references to Zephyr
                  Monitors include https://zephyrmonitors.com/, https://aio.zephyrmonitors.com/, and https://links.zephyrmonitors.com/.
                </p>

                <div className='sub-title'>Purpose</div>
                <p>The Zephyr Monitors website is a new and existing client management and marketing site. By using the Zephyr Monitors website, you consent to the data practices described in this statement.</p>

                <div className='sub-title'>Collection of your Personal Information</div>
                <p>
                  In order to better provide you with products and services offered on our Site, Zephyr Monitors may collect personally identifiable information, such as your:
                  <br />
                  - First and Last Name
                  <br />
                  - Mailing Address
                  <br />
                  - E-mail Address
                  <br />
                  - Phone Number
                  <br />
                  - Discord.com information
                  <br />- Anonymous browser and mobile device data All information collected by us is shown above unless you voluntarily provide more information to us. However, you may be required to provide certain personal information to
                  us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
                  signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. We
                  will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the
                  future.
                </p>

                <div className='sub-title'>Billing and Credit Card Information</div>
                <p>
                  If you purchase Zephyr Monitor’s products and services, we collect billing and credit card information which is processed through Stripe and/or Paypal, both are third-party processors. This information is used to complete
                  the purchase transaction and all information is protected through all steps of checkout.
                </p>

                <div className='sub-title'>Use of your Personal Information</div>
                <p>
                  Zephyr Monitors collects and uses your personal information to operate its website(s) and deliver the services you have requested.
                  <br />
                  Zephyr Monitors may also use your personally identifiable information to inform you of other products or services available from Zephyr Monitors and its affiliates.
                </p>

                <div className='sub-title'>Sharing Information with Third Parties</div>
                <p>
                  Zephyr Monitors does not sell, rent or lease its customer lists to third parties.
                  <br />
                  <br />
                  Zephyr Monitors may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from
                  using your personal information except to provide these services to Zephyr Monitors, and they are required to maintain the confidentiality of your information.
                  <br />
                  <br />
                  Zephyr Monitors may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
                  process served on Zephyr Monitors or the site; (b) protect and defend the rights or property of Zephyr Monitors; and/or (c) act under exigent circumstances to protect the personal safety of users of Zephyr Monitors, or the
                  public.
                </p>

                <div className='sub-title'>Automatically Collected Information</div>
                <p>
                  Information about your computer hardware and software may be automatically collected by Zephyr Monitors. This information can include: your IP address, browser type, domain names, access times and referring website
                  addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Zephyr Monitors website.
                </p>

                <div className='sub-title'>Use of Cookies</div>
                <p>
                  The Zephyr Monitors website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver
                  viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                  <br />
                  <br />
                  One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize
                  Zephyr Monitors pages, or register with Zephyr Monitors site or services, a cookie helps Zephyr Monitors to recall your specific information on subsequent visits. This simplifies the process of recording your personal
                  information, such as billing addresses, shipping addresses, and so on. When you return to the same Zephyr Monitors website, the information you previously provided can be retrieved, so you can easily use the Zephyr
                  Monitors features that you customized.
                  <br />
                  <br />
                  You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may
                  not be able to fully experience the interactive features of the Zephyr Monitors services or websites you visit.
                </p>

                <div className='sub-title'>Links</div>
                <p>
                  This website contains links to other sites. Users should be aware and understand we do not take any liability for actions once leaving our website. Please be aware that we are not responsible for the content or privacy
                  practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
                </p>

                <div className='sub-title'>Security of your Personal Information</div>
                <p>
                  Zephyr Monitors secures your personal information from unauthorized access, use, or disclosure. Zephyr Monitors uses the following methods for this purpose:
                  <br />
                  - SSL Protocol
                  <br />
                  <br />
                  When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol as well as being processed through
                  Stripe and/or PayPal, a secure third party.
                  <br />
                  <br />
                  We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be
                  guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control;
                  and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
                </p>

                <div className='sub-title'>Users Rights</div>
                <p>
                  Some states may require websites to provide data collected in a readable format back to the user if requested. Should this be the case, the user may respond to the contact email below to receive any information collected
                  by the website during their interactions with the site.
                  <br />
                  <br />
                  Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: • Delete your personal information from our records; and • Direct any service providers to delete your personal information
                  from their records.
                  <br />
                  <br />
                  Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
                  <br />
                  • Comply with the California Electronic Communications Privacy Act;
                  <br />
                  • Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you,
                  or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
                  <br />
                  • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
                  <br />
                  • Debug to identify and repair errors that impair existing intended functionality;
                  <br />
                  • Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;
                  <br />
                  • Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to
                  render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;
                  <br />
                  • Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;
                  <br />
                  • Comply with an existing legal obligation; or
                  <br />• Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.
                </p>

                <div className='sub-title'>Children Under Thirteen</div>
                <p>
                  Zephyr Monitors does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you are not permitted to use this website unless a parent or
                  guardian has signed off on this usage as well as contacted us.
                </p>

                <div className='sub-title'>Disconnecting your Zephyr Monitors Account from Third Party Websites</div>
                <p>
                  You will be able to connect your Zephyr Monitors account to third party accounts. BY CONNECTING YOUR ZEPHYR MONITORS ACCOUNT TO YOUR THIRD-PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS
                  RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD-PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS
                  MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third-party account at any time. Users may disconnect their discord account from this website by going into settings on their discord and
                  deauthorizing the Zephyr Monitors discord bot.
                </p>

                <div className='sub-title'>E-mail Communications</div>
                <p>From time to time, Zephyr Monitors may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>

                <div className='sub-title'>External Data Storage Sites</div>
                <p>
                  We may store your data on servers provided by third party hosting vendors with whom we have contracted. Zephyr Monitors and its affiliates hold no responsibility for data breaches occurring on these third-party hosting
                  sites, as due diligence has been performed to choose reputable and secure vendors.
                </p>

                <div className='sub-title'>Changes to this Statement</div>
                <p>
                  Zephyr Monitors reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address
                  specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such
                  modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
                </p>

                <div className='sub-title'>Contact Information</div>
                <p>
                  Zephyr Monitors welcomes your questions or comments regarding this Statement of Privacy. If you believe that Zephyr Monitors has not adhered to this Statement, please contact Zephyr Monitors at:
                  <br />
                  <br />
                  Zephyr Monitors 43547 Southerland Way Fremont, CA 94539
                  <br />
                  Email Address: zephyraio@zephyrmonitors.com
                  <br />
                  <br />
                  <br />
                  <br />
                  Effective as of January 06, 2021
                </p>
              </div>
              </div>)
   }
}
