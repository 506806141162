export const getAvatarImageURLFromUser = (discordUser) => {
  return `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`;
};

export const getFullDiscordNameFromUser = (discordUser) => {
  return `${discordUser.username}#${discordUser.discriminator}`;
};

export const setCookie = (name, value) => {
  let today = new Date();
  let expiry = new Date(today.getTime() + 604800 * 1000);
  document.cookie = name + "=" + escape(value) + ";" + expiry.toGMTString() + " domain=zephyrmonitors.com";
};
