import React, {useEffect, useState} from "react";
import Nav from "../General/Nav/nav.jsx";
import LandingPage from "./landingpage/index.jsx";
import "./styles.scss";
import {useParams} from "react-router-dom";

const WaitingRoom = () => {
  const [discordUser, setDiscordUser] = useState();
  const {service} = useParams();

  useEffect(() => {
    const userInfo = sessionStorage.getItem("discordUser");
    if (userInfo?.length > 0) setDiscordUser(JSON.parse(userInfo));
  }, []);

  return (
    <React.Fragment>
      <Nav showFeatures={true} />
      <main>
        <LandingPage setDiscordUser={setDiscordUser} discordUser={discordUser} service={service} />
      </main>
    </React.Fragment>
  );
};

export default WaitingRoom;
