const discordbotFields = [
    {
         label: "Server Id",
         description:["Snowflake (Number/Integer) Id Only","Right click your server's icon in your app and click 'Copy Id' to get this id."],
         dataField: "server"
    }
   //     , {
 //       label: "Authorized Roles",
 //        dataField: "authorizedRoles",
 ////        description:["Snowflake (Number/Integer) Id Only",
   //      "Please do not enter the role's '#' (role_name#0001) based id for this, it will not work."],
   //       isArray: true}
  ];
  
  export default discordbotFields;
  