import React from "react";
import "./styles.scss";

import Load from "../../assets/images/z-loader.gif";

const Loader = () => {
  return (
    <div className='loader-background'>
      <div className='loader-container'>
        <img src={Load} alt='' />
      </div>
    </div>
  );
};

export default Loader;
