import React, {useEffect, useState, useCallback} from "react";
import {useHistory} from "react-router-dom";
import { getDiscordAccessToken} from "../../../util/discordapi";
import {getFullDiscordNameFromUser} from "../../../util/util";

const LandingPage = ({setDiscordUser, discordUser, service}) => {
  // Need to set up error box
  const [error, setError] = useState(false);
  const history = useHistory();

  const onLogout = () => {
    sessionStorage.removeItem("discordUser");
    sessionStorage.removeItem("accessToken");
    setDiscordUser(null);
  };

  const styledServiceString = () => {
    const serv = service[0].toUpperCase() + service.slice(1);
    if (service === "monitors") return serv + " - you have taken the first step to building the ultimate Discord server";
    else return serv + " - the all in one information provider for your Discord server.";
  };

  /**
   * Changes the landing page depending on if the user signed in already
   */
  const generateLandingPageContent = () => {
    if (!discordUser)
      return (
        <React.Fragment>
          <div className='form-content'>
            <h1 className='apply-header'>Apply Today</h1>
            <p>Welcome to Zephyr {styledServiceString()}</p>
            <p>Start your application by signing in through Discord below:</p>
            <div className='cta'>
              <button onClick={() => openDiscordOAuth()} className='btn continue'>
                Sign in
                <div className='btn2'></div>
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    else {
      return (
        <React.Fragment>
          <div className='form-content'>
            <h1 className='apply-header'>Welcome {getFullDiscordNameFromUser(discordUser)}</h1>
            <p>Click the button below to continue with your application:</p>
            <br />
            <div className='btn-container'>
              <button
                onClick={() => {
                  onLogout();
                }}
                className='btn logout'>
                Log out
              </button>
              <button
                onClick={() => {
                  history.push(`/apply/${service}/form`);
                }}
                className='btn continue'>
                Continue
                <div className='btn2'></div>
              </button>
            </div>

          </div>
        </React.Fragment>
      );
    }
  };

  /**
   * Opens discord oauth form in current window
   */

  const openDiscordOAuth = () => {
    window.location =
      service !== "monitors" && service !== "Monitors"
        ? "https://discord.com/api/oauth2/authorize?client_id=860986180891705364&redirect_uri=https%3A%2F%2Fwww.zephyrmonitors.com%2Fapply%2Flinks&response_type=code&scope=identify%20guilds%20guilds.join"
        : "https://discord.com/api/oauth2/authorize?client_id=860986180891705364&redirect_uri=https%3A%2F%2Fwww.zephyrmonitors.com%2Fapply%2Fmonitors&response_type=code&scope=guilds%20guilds.join%20identify";
  };

  /**
   * Fetches the discord token and gets the user's profile
   */
  const handleFetching = useCallback(
    async (token) => {
      const user = await getDiscordAccessToken(token, service === "monitors" ? "https://www.zephyrmonitors.com/apply/monitors" : "https://www.zephyrmonitors.com/apply/links");
      if (user) {
        return user;
      } else {
        setError(true);
      }
    },
    [service]
  );

  /**
   * After oauth success it pulls the code and sets up the discordUser for the application
   */
  useEffect(() => {
    const getDiscordInfo = async (tokenString) => {
      const userInfo = await handleFetching(tokenString);

      if (userInfo) {
        sessionStorage.setItem("discordUser", JSON.stringify(userInfo.info));
        sessionStorage.setItem("accessToken", userInfo.token);
        setDiscordUser(userInfo.info);
      } else setError(true);
    };

    if (window.location.search.includes("code") && !discordUser) {
      const tokenString = window.location.search.split("=")[1];
      sessionStorage.setItem("token", tokenString);
      getDiscordInfo(tokenString);
    }
  }, [discordUser, handleFetching, setDiscordUser]);

  return (
    <div className='landing-hero'>
      <div className='landing-bg'></div>
      <div className='landing-container'>
        <div className='form-outer'>
          <div className='form-content'>{generateLandingPageContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
