import React, {useReducer, useState} from "react";
import {getAvatarImageURLFromUser, getFullDiscordNameFromUser} from "../../../util/util";
import Card from "../card";
import {initialState, reducer, fields} from "./reducer";
import axios from "axios";
import {useHistory} from "react-router-dom";

const Form = ({discordUser, service}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleChange = (type, e) => {
    dispatch({
      type: type,
      field: e.target.name,
      payload: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    const processFormResponseForDiscord = (str) => {
      if (str.length > 0) return str;
      else return "**NO ANSWER PROVIDED**";
    };
    e.preventDefault();

    // Check if they agree
    if (state.consent === "I agree") {
      setLoading(true);
      // Handle if other contact method is chosen
      const body = {
        service: service,
        applicant: getFullDiscordNameFromUser(discordUser),
        imageURL: getAvatarImageURLFromUser(discordUser),
        id: discordUser.id,
        groupName: processFormResponseForDiscord(state.groupName),
        socialMedia: processFormResponseForDiscord(state.socialMedia),
        prevProvider: processFormResponseForDiscord(state.prevProvider),
        groupPricing: processFormResponseForDiscord(state.groupPricing),
        consent: state.consent,
        accessToken: sessionStorage.getItem("accessToken")
      };

      await axios
        .post("https://application-api.zephyrmonitors.com/api/application", JSON.stringify(body), {
          headers: {
            "x-api-key": "A39249AEBB8E19EB32A676F4A1D1B"
          }
        })
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.removeItem("discordUser");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("accessToken");
            history.push("/success");
          }
        })
        .catch((err) => {
          setError(true);
          console.log(err);
        });
    } else {
      setError(true);
    }
  };

  const generateFields = (fields) => {
    return fields.map((field) => {
      return (
        <Card
          key={field.name}
          name={field.name}
          label={field.label}
          subText={field.subText}
          placeholder={field.placeholder}
          imgUrl={field.imgUrl}
          inputType={field.type}
          inputValue={state[field.name]}
          radioFields={field.radioFields}
          handleChange={handleChange}
          state={state}
          service={service}
        />
      );
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {generateFields(fields)}{" "}
      {!loading && (
        <button type={"submit"} className='submit-btn'>
          Submit Application
        </button>
      )}
      {loading && <button className='submit-btn'>Submit Application</button>}
    </form>
  );
};

export default Form;
