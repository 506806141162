/**
 * If you need to update the form just add a property to this
 */
export const fields = [
  {
    name: "groupName",
    label: "Company Name",
    subText: null,
    type: "text",
    imgUrl: "https://zephyrmonitors.s3.amazonaws.com/waiting-room/group-name.svg",
    placeholder: "Enter Name"
  },
  {
    name: "socialMedia",
    label: "Primary Social Media Link",
    subText: null,
    type: "text",
    imgUrl: "https://zephyrmonitors.s3.amazonaws.com/waiting-room/twitter.svg",
    placeholder: "Enter Link"
  },
  {
    name: "prevProvider",
    label: "Are you switching from a different provider? If so, which?",
    subText: null,
    type: "text",
    imgUrl: "https://zephyrmonitors.s3.amazonaws.com/waiting-room/provider.svg",
    placeholder: "Enter Provider"
  },
  {
    name: "groupPricing",
    label: "Referral",
    subText: `How did you hear about us?`,
    type: "text",
    imgUrl: "https://zephyrmonitors.s3.amazonaws.com/waiting-room/pricing.svg",
    placeholder: "Enter Referral"
  },
  {
    name: "consent",
    label: "Terms of Service",
    subText: ``,
    type: "radio",
    radioFields: ["I agree", "I disagree"],
    imgUrl: "https://zephyrmonitors.s3.amazonaws.com/waiting-room/terms.svg",
    placeholder: ""
  }
];

/**
 * Add default value to this too
 * Can be combined later
 */
export const initialState = {
  groupName: "",
  socialMedia: "",
  contactMethod: "Discord",
  otherInfo: "",
  prevProvider: "",
  groupPricing: "",
  consent: "I disagree"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_INPUT_TEXT":
      return {
        ...state,
        [action.field]: action.payload
      };
    case "HANDLE_INPUT_RADIO":
      return {
        ...state,
        [action.field]: action.payload
      };
    case "HANDLE_CLEAR_OPTIONAL_FIELD":
      return {
        ...state,
        otherInfo: ""
      };
    default:
      return state;
  }
};
