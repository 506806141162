import React, {useState, useEffect} from "react";
import {getAvatarImageURLFromUser, getFullDiscordNameFromUser} from "../../../util/util";
import {useHistory} from "react-router-dom";
import Nav from "../../General/Nav/nav";
import Form from "../form/condensed";

const LinksFormPage = () => {
  const [discordUser, setDiscordUser] = useState();
  const history = useHistory();

  useEffect(() => {
    const userInfo = sessionStorage.getItem("discordUser");
    if (userInfo?.length > 0) setDiscordUser(JSON.parse(userInfo));
    else history.push("/apply/links");
  }, [history]);

  return (
    <React.Fragment>
      <Nav />
      <main className='landing-hero'>
        <div className='apply-container'>
          <h1 className='form-header'>Apply Now</h1>
          <div className='apply-split'>
            <div className='left-column'>
              <div className='card'>
                <div className='card-header'>
                  <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/info.svg' alt='' />
                  <h2 className='card-title'>General Info | Pricing</h2>
                </div>
                <div className='card-description'>
                  <p>$150-400 USD Monthly - Pricing is variable based on your group’s size. Apply for a quote! Payments are made through our preferred method: Stripe (Credit or Debit card), or PayPal</p>
                  <p style={{margin: ".5rem 0"}}>Zephyr Links is non-modular. Meaning, we only offer the full package in one price. All new sites and additional features are included at no extra cost.</p>
                  <p>If approved, we will contact you as soon as possible! You will be invited to the client server where you can sample our features and view scrapers before deciding to purchase.</p>
                </div>
              </div>
              <div className='card-gradient'>
                <div className='card-header'>
                  <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/promo.svg' alt='' />
                  <h2 className='card-title'>Pricing Promotion</h2>
                </div>

                <div className='card-description'>
                  <p>Current Zephyr Monitor clients receive a discount to Zephyr Links!</p>
                  <p>If you are a Zephyr Monitors client, please let us know when you join the client server.</p>
                </div>
              </div>
              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/cloud-page.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/CloudDash.svg' alt='' />
                    <h2 className='card-title'>Cloud Dashboard</h2>
                  </div>

                  <div className='card-description'>
                    <p>- Access all scrapers at any time</p>
                    <p>- One click export with Discord webhooks or clipboard</p>
                    <p>- Keyword search with positive and negative terms</p>
                    <p>- Grant access to your staff team</p>
                  </div>
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/early.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/Early.svg' alt='' />
                    <h2 className='card-title'>Early Link Release Channels</h2>
                  </div>

                  <div className='card-description'>
                    <p>- Automated early link channels created in your server for major releases</p>
                  </div>
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/shopify-page.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/Shopify.svg' alt='' />
                    <h2 className='card-title'>Shopify Link Scraping</h2>
                  </div>

                  <div className='card-description'>
                    <p>- 50+ Shopify sites supported</p>
                    <p>- Private Shopify requests for any compatible Shopify site</p>
                    <p>- Kith Early Links provided</p>
                  </div>
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/mesh-page.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/PID.svg' alt='' />
                    <h2 className='card-title'>Mesh PID Scraping</h2>
                  </div>

                  <div className='card-description'>
                    <p>- 6+ Mesh Sites Supported</p>
                    <p>- PIDs & Images </p>
                  </div>
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/aio-page.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/AIO.svg' alt='' />
                    <h2 className='card-title'>AIO Link Scraping</h2>
                  </div>

                  <div className='card-description'>
                    <p>- 7+ AIO Sites Supported</p>
                    <p>- Early Links, PIDs, Variants, or Images </p>
                  </div>
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/form-images/social-page.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/Social.svg' alt='' />
                    <h2 className='card-title'>Social Media Scraping</h2>
                  </div>

                  <div className='card-description'>
                    <p className='feature'>- 100+ Instagram Accounts Monitored</p>
                  </div>
                </div>
              </div>
              {discordUser && <Form discordUser={discordUser} service={"links"} />}
            </div>
            <div className='right-column'>
              <div className='summary-container'>
                <div className='card-header'>Application Summary</div>
                <div className='profile-summary'>
                  <div className='profile-content'>
                    {discordUser && (
                      <React.Fragment>
                        <img src={getAvatarImageURLFromUser(discordUser)} alt='' />
                        <p>{getFullDiscordNameFromUser(discordUser)}</p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className='break-blue'></div>
                <div className='summary-title'>Sites and Features</div>
                <div className='sitelist-content'>
                  <div className='sitelist-1'>
                    <p>Cloud Dashboard</p>
                    <p>Early Link Release Channels</p>
                    <p>Shopify Link Scraping</p>
                    <p>Mesh PID Scraping</p>
                    <p>AIO Link Scraping</p>
                    <p>Social Media Monitors</p>
                  </div>
                </div>
                <div className='break-purple'></div>
                <div className='summary-title'>Bundle Summary</div>
                <div className='summary'>
                  <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/z-logo.svg' alt='' />
                  <p>1x Zephyr Links Plan</p>
                </div>
                <div className='break-purple'></div>
                <div className='total-container'>
                  <h1 className='total'>Total</h1>
                  <h1 className='plan-price'>
                    $150.00-$400.00
                    <span className='month-tag'>/mo</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default LinksFormPage;
