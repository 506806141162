const generalFields = [
  {label: "Group Name", dataField: "name"},
  {label: "Footer Image", dataField: "avi"},
  {label: "Webhook Image", dataField: "webhookAVI"},
  {label: "Footer Text", dataField: "footerText"},
  {label: "Group Color", dataField: "color"},
  
  {label: "New Shopify Webhooks Discord Category Id (If this is set then companion bot will automatically make and save new channels+webhooks under this category)", dataField: "shopify_category"},
  {label: "New AIO Webhooks Discord Category Id", dataField: "aio_category"},
  {label: "New Flips Webhooks Discord Category Id", dataField: "flips_category"},
  {label: "New Nike/Snkrs Webhooks Discord Category Id", dataField: "nike_category"},
  {label: "New Adidas Webhooks Discord Category Id", dataField: "adidas_category"},
  {label: "New Misc Webhooks Discord Category Id", dataField: "misc_category"}
];

export default generalFields;
