import React from "react";
import Nav from "../../General/Nav/nav";

const ThankYou = () => {
  return (
    <React.Fragment>
      <Nav />
      <div className='landing-hero'>
        <div className='landing-bg'></div>
        <div className='landing-container'>
          <div className='form-outer'>
            <div className='form-content'>
              <h1 className='apply-header'>Thank you for your application!</h1>
              <p>You have been added to the Zephyr Waiting Room server where you will be contacted with your response.</p>
              <p>Please refer to your ticket for any questions or support. </p>
              <p>We will contact you within 2 days with our decision.</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThankYou;
