import React from "react";

import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../../assets/images/logo.svg";

const NavModal = () => {
  return (
    <div className='nav-modal'>
      <div className='dropdown-nav'>
        <Logo id='nav-logo' className='logo-nav' />
        <div className='nav--items'>
          <div className='nav-item'>
            <Link to='/'>Home</Link>
          </div>
          <div className='nav-login'>
            <Link to='/dashboard'>Dashboard</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavModal;
