import {createSlice} from "@reduxjs/toolkit";

export const serverConfigSlice = createSlice({
  name: "general",
  initialState: {

    name: "",

    avi: "",
    webhookAVI: "",


    footerText: "",
    color: "",


    monitorupdates: ""

  },
  reducers: {
    update: (state, action) => {

      const update = state;

      update[action.payload.key] = action.payload.value;

      return update;
    },
    updateAll: (state, action) => {

      const update = action.payload;

      return update;
    }
  }
});

export const {update, updateAll} = serverConfigSlice.actions;

export const selectState = (state) => state;

export default serverConfigSlice.reducer;
