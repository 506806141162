import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {getAvatarImageURLFromUser, getFullDiscordNameFromUser} from "../../../util/util";
import Nav from "../../General/Nav/nav";
import Form from "../form/condensed";

import "../styles.scss";
import adidasFields from "../../Webhooks/data/adidas";
import aioFields from "../../Webhooks/data/aio";
import flipsFields from "../../Webhooks/data/flips";
import nikeFields from "../../Webhooks/data/nike";
import otherFields from "../../Webhooks/data/other";
import scraperFields from "../../Webhooks/data/scraper";

import shopifyFields from "../../Webhooks/data/shopify";
import socialFields from "../../Webhooks/data/social";
import supremeFields from "../../Webhooks/data/supreme";
const allFieldsTogether = [...shopifyFields, ...supremeFields, ...nikeFields, ...flipsFields, ...aioFields, ...adidasFields, ...otherFields, ...socialFields, ...scraperFields].filter((t) => {
  const field = t.dataField;
  if (!field.endsWith("2") && field !== "monitorupdates") {
    return true;
  } else {
    return false;
  }
});
const MonitorsFormPage = () => {
  const [discordUser, setDiscordUser] = useState();
  const history = useHistory();

  useEffect(() => {
    const userInfo = sessionStorage.getItem("discordUser");
    if (userInfo?.length > 0) setDiscordUser(JSON.parse(userInfo));
    else history.push(`/apply/monitors`);
  }, [history]);

  const generateSiteList = (half) => {
    return allFieldsTogether.map((element, index) => {
      console.log(element);
      if (index % 2 === 0 && half) {
        return (
          <li
            key={element.dataField}
            style={{
              fontWeight: "Bold"
            }}>
            {element.label}
          </li>
        );
      } else {
        return (
          <li
            key={element.dataField}
            style={{
              fontWeight: "Bold"
            }}>
            {element.label}
          </li>
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Nav />
      <div className='landing-hero'>
        <div className='apply-container'>
          <h1 className='form-header'>Interest Form</h1>
          <div className='apply-split'>
            <div className='left-column'>
              <div className='card'>
                <div className='card-header'>
                  <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/info.svg' alt='' />
                  <p className='card-title'>General Info | Pricing</p>
                </div>
                <div className='card-description'>
                  Thank you for expressing interest in Zephyr Monitors! To get started, fill out this form to the best of your ability.
                  <br />
                  <br />
                  Upon submission, you will be granted to our waiting room, where you can ask questions and view the monitors.
                  <br />
                  <br />
                  If you are unable to answer any of the questions, simply put "N/A". You can adjust any of this information later.
                </div>
              </div>

              <div className='card-gradient'>
                <div className='card-header'>
                  <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/promo.svg' alt='' />
                  <p className='card-title'>Pricing</p>
                </div>

                <div className='card-description'>
                  All Zephyr Monitors plans are custom tailored to your group. We have reduced prices now compared to before. To get a quote, fill out this form and our team will contact you shortly!
                  <br />
                  <br />
                  New to Zephyr? We offer a free trial period and pricing discount for all new customers!
                </div>
              </div>

              <div className='card'>
                <div className='companion-content'>
                  <img className='companion-img' src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/client-bg.png' alt='' />
                  <div className='companion-header'>
                    <img src='https://zephyrmonitors.s3.amazonaws.com/waiting-room/companion.svg' alt='' />
                    <p className='card-title'>Companion Bot</p>
                  </div>

                  <div className='card-description'>
                    Available to all clients at no additional cost!
                    <br />
                    Group Added Pids for Ticketmaster,Walmart,Target
                    <br />
                    Twitter success poster
                    <br />
                    Shopify add-to-cart link generator
                    <br />
                    Shopify variant generator
                    <br />
                    Shopify shipping checker
                    <br />
                    Shopify monitor delay generator
                    <br />
                    Nike stock checker
                    <br />
                    Bot downloads
                    <br />
                    Fee calculator
                    <br />
                    Delay calculator
                    <br />
                    Zephyr Quicktask Manager
                    <br />
                    StockX search
                    <br />
                    StockX and GOAT fee calculator
                    <br />
                    Zephyr sitelist checker
                    <br />
                    <br />
                    Keyword Pinger System
                    <br />
                    -Individual members can manage personal keywords
                    <br />
                    -Members are alerted for pickups in a separate channel
                    <br />
                    <br />
                    Embed Pinger System
                    <br />
                    -Setup pingers to @ users on restocks
                    <br />
                    <br />
                    Release Channel System
                    <br />
                    -Owners can setup monitor channels with customized keywords
                    <br />
                    -Create channels for individual release monitors
                  </div>
                </div>
              </div>

              {discordUser && <Form discordUser={discordUser} service={"monitors"} />}
            </div>
            <div className='right-column'>
              <div className='summary-container'>
                <div className='card-header'>Application Summary</div>
                <div className='profile-summary'>
                  <div className='profile-content'>
                    {discordUser && (
                      <React.Fragment>
                        <img src={getAvatarImageURLFromUser(discordUser)} alt='' />
                        <p>{getFullDiscordNameFromUser(discordUser)}</p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className='break-blue'></div>
                <div className='summary-title'>Sitelist</div>
                <div className='sitelist-content'>
                  <div className='sitelist-1'>
                    <ul>{generateSiteList(true)}</ul>
                  </div>
           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonitorsFormPage;
