import React, {useState} from "react";
import {useForm} from "react-hook-form";
import submit from "../../actions/submit"
import {useSelector, useDispatch} from "react-redux";

import {updateAll} from "../../features/serverconfig";
import generalFields from "../Webhooks/data/general";

const GeneralInfo = () => {
  const {register, setValue, handleSubmit} = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const settings = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const onSubmit = async (enteredData) => {
    dispatch(updateAll(enteredData));
    setIsLoading(true);
    const newData = Object.assign({"type": 'general'}, enteredData);

    await submit(newData)
      .then((res) => {
        const response = res.data;

        if (response.status) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  }
	const generateFields = () => {

return generalFields.map(field => {
			console.log(field);
	const key = field.dataField;
	const label = field.label;
	const value = settings[key];
			setValue(key, value);
			return (
				<React.Fragment key={"inputFor" + key}>
					<label htmlFor={key}>{label}</label>
					<input
						type="text"
						placeholder={key}
						{...register(key)}
						disabled={
							field.disabled
						}
					/>
				</React.Fragment>
			);
		});
	};
	return (
		<div className="general-container">
			<p className="setting-title">Server Configuration</p>
			<div className="general-background">
				<form onSubmit={handleSubmit(onSubmit)}>
					{generateFields()}
					<div className="save-btn-container">
						{!isLoading ? (
							<button
								className="btn save break"
								type="submit"
								id="saveInfo"
							>
								Save Changes
							</button>
						) : (
							<button className="btn save break" id="saveInfo">
								Loading
							</button>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default GeneralInfo;
