import {createSlice} from "@reduxjs/toolkit";
import adidasFields from "../../components/Webhooks/data/adidas";
import aioFields from "../../components/Webhooks/data/aio";
import flipsFields from "../../components/Webhooks/data/flips";
import nikeFields from "../../components/Webhooks/data/nike";
import otherFields from "../../components/Webhooks/data/other";
import scraperFields from "../../components/Webhooks/data/scraper";

import shopifyFields from "../../components/Webhooks/data/shopify";
import socialFields from "../../components/Webhooks/data/social";
import supremeFields from "../../components/Webhooks/data/supreme";
import aioV2Fields from "../../components/Webhooks/data/aiov2";

const handleFieldSetSwap = (page) => {
  switch (page) {
    case "AIO Webhooks":
      return aioFields;

    case "Adidas Webhooks":
      return adidasFields;

    case "Flips Webhooks":
      return flipsFields;

    case "Nike Webhooks":
      return nikeFields;

    case "Supreme Webhooks":
      return supremeFields;

    case "Checkout Feeds/Other Webhooks":
      return otherFields;

    case "AIOv2 Webhooks":
      return aioV2Fields;

    case "Scraper Webhooks":
      return scraperFields;

    case "Social Media Webhooks":
      return socialFields;


      
    default:
      return shopifyFields;
  }
};

export const fieldsSlice = createSlice({
  name: "fields",
  initialState: shopifyFields,
  reducers: {
    set: (state, action) => {
      const page = action.payload;

      const fields = handleFieldSetSwap(page);

      return fields;
    }
  }
});

export const {set} = fieldsSlice.actions;

export const selectState = (state) => state.fields;

export default fieldsSlice.reducer;
