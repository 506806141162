import React, {useState} from "react";
import {useForm, useFieldArray} from "react-hook-form";
import submit from "../../actions/submit"
import {useSelector, useDispatch} from "react-redux";
import "../GeneralInfo/styles.scss"

import discordbotFields from "../Webhooks/data/discordbot";
import {updateAll} from "../../features/discordbotconfig";


const DiscordBotInfo = () => {
  const discordbot = useSelector((state) => state.discordbot);
  const {control, register, setValue, handleSubmit} = useForm({
    defaultValues: {
      authorizedUsers: discordbot.authorizedUsers,
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (enteredData) => {
    dispatch(updateAll(enteredData));
    setIsLoading(true);
    const newData = Object.assign({"type": 'discordbot'}, enteredData);

    await submit(newData)
    .then((res) => {
        const response = res.data;

        if (response.status) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
  const generateDesc = (desc) => {
     return <p className={"input-desc"}>{desc}</p>;
  };
  const generateFields = () => {

    return discordbotFields.map((input) => {
      setValue(input.dataField, discordbot[input.dataField]);
      return (
        <React.Fragment key={"inputFor" + input.dataField} data-tip={input.dataField}>
          <label htmlFor={input.dataField}>{input.label}</label>
          {input.description &&  generateDesc(input.description)}
          {generateRegularInput(input.label, input.dataField)}
        </React.Fragment>
      );
    });
  };
  
  const generateRegularInput = (label, data) => {

      return  (<input type='text'  placeholder={label} {...register(data)} />);

  };
  const { fields, append,remove } = useFieldArray({
    control,
    name: "authorizedUsers"
    
  });
  return (
    <div className='general-container'>
      <p className='setting-title'>Discord Bot Configuration</p>
      <div className='general-background'>
        <form onSubmit={handleSubmit(onSubmit)}>
          {generateFields()}


          <React.Fragment key={"inputFor" + "authorizedUsers"} data-tip={"authorizedUsers"}>
          <label htmlFor={"authorizedUsers"}>Authorized Users</label>
          {generateDesc("Snowflake (Number/Integer) Id Only. Please do not enter the user's '#' (user_name#0001) based id for this, it will not work.")}


        {fields.map((item, index) => (
          <li style={{    "list-style-type": "none"}} key={item.id}>
            <input {...register(`authorizedUsers.${index}.value`)} />

            <div className='btn invite' style={{"width": "80px", "height":"40px", "margin-bottom":"30px", "font-size":"13px", "padding":"10px", "border-radius":"3px", "background":"darkred"}}  onClick={() => remove(index)}>Delete</div>
       
          </li>
        ))}
   
         <div className='btn invite' style={{"width": "40px", "height":"40px", "font-size":"26px", "padding":"0px", "border-radius":"3px", "background":"darkgreen"}}  onClick={() => append({"value":""})}>+ </div>
       
   
      </React.Fragment>



          <div className='save-btn-container'>
            {!isLoading ? (
              <button className='btn save break' type='submit' id='saveInfo'>
                Save Changes
              </button>
            ) : (
              <button className='btn save break' id='saveInfo'>
                Loading
              </button>
            )}
          </div> 
          <div className='tip-container'>
            <p>Use !zephyradmin to list admin commands</p>
          </div>
          <div className='tip-container'>
            <p>May take up to 5 minutes for changes to be applied</p>
          </div>
          <div className='btn invite'>
          <a href={"https://discord.com/api/oauth2/authorize?client_id=595079249468063744&permissions=11264&scope=bot"}>Invite Zephyr Bot</a>
        </div>
        </form>
      </div>
    </div>
  );
};

export default DiscordBotInfo;
