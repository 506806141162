import {createSlice} from "@reduxjs/toolkit";

export const discordConfigSlice = createSlice({
  name: "discordbot",
  initialState: {
    server: "",
    authorizedUsers: []
  },
  reducers: {
    update: (state, action) => {

      const update = state;

      update[action.payload.key] = action.payload.value;

      return update;
    },
    updateAll: (state, action) => {

      const update = action.payload;

      return update;
    }
  }
});

export const {update, updateAll} = discordConfigSlice.actions;

export const selectState = (state) => state;

export default discordConfigSlice.reducer;
