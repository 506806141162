import React from "react";
import WebhookForm from "./webhookform";

import aioFields from "./data/aio";
import adidasFields from "./data/adidas";
import flipsFields from "./data/flips";

import nikeFields from "./data/nike";
import scraperFields from "./data/scraper";
import otherFields from "./data/other";
import shopifyFields from "./data/shopify";
import socialFields from "./data/social";
import supremeFields from "./data/supreme";

const Webhook = ({page}) => {
  const generateForm = () => {
    switch (page) {
      case "Flips Webhooks":
        return <WebhookForm  key={page} customFields={flipsFields} />;
      case "Social Media Webhooks":
        return <WebhookForm key={page} customFields={socialFields} />;
      case "Scraper Webhooks":
        return <WebhookForm  key={page} customFields={scraperFields} />;
      case "Supreme Webhooks":
        return <WebhookForm  key={page} customFields={supremeFields} />;
      case "AIO Webhooks":
        return <WebhookForm  key={page} customFields={aioFields} />;
      case "Nike Webhooks":
        return <WebhookForm  key={page} customFields={nikeFields} />;
      case "Adidas Webhooks":
        return <WebhookForm  key={page} customFields={adidasFields} />;
      case "Checkout Feeds/Other Webhooks":
        return <WebhookForm  key={page} customFields={otherFields} />;
      default:
        return <WebhookForm key={page} customFields={shopifyFields} />;
    }
  };

  return (
    <div className='hook-container'>
      <p className='hook-title'>{page}</p>
      <div className='shopify-fields'>
        <div className='input'>{generateForm()}</div>
      </div>
    </div>
  );
};

export default Webhook;
